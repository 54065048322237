import React from "react";
import "./explorecontainer.css";
import { useNavigate } from "react-router-dom";
import { ReactComponent as RepaymentIcon } from "../../../assets/navbar/repayment.svg";
import { ReactComponent as SaveIcon } from "../../../assets/navbar/save.svg";
import { ReactComponent as MembersIcon } from "../../../assets/navbar/members.svg";
import { ReactComponent as LoanIcon } from "../../../assets/navbar/loan.svg";
import { ReactComponent as StorageIcon } from "../../../assets/navbar/storage.svg";
import { ReactComponent as VouchersIcon } from "../../../assets/navbar/vouchers.svg";
import { ReactComponent as ApplyloansIcon } from "../../../assets/navbar/applyloans.svg";
import { ReactComponent as InterestIcon } from "../../../assets/navbar/interest.svg";
import { ReactComponent as ChatIcon } from "../../../assets/navbar/Chat.svg";
import { AnimatePresence, motion } from "framer-motion";

function ExploreContainer() {
  const navigate = useNavigate();
  return (
    <main className="explore-container">
      {/* <motion.div
        className="explore-icon  explore-icon-loans"
        onClick={() => navigate("/admin/payments")}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.15 }}
      >
        {" "}
        <PaymentsIcon />
        <label>Payments</label>
      </motion.div>{" "} */}
      <motion.div
        className="explore-icon"
        onClick={() => navigate("/admin/members")}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.15, delay: 0.01 }}
      >
        {" "}
        <MembersIcon />
        <label>Members</label>
      </motion.div>{" "}
      <motion.div
        className="explore-icon"
        onClick={() => navigate("/admin/loans")}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.15, delay: 0.02 }}
      >
        <LoanIcon />
        <label>Loans</label>
      </motion.div>
      <motion.div
        className="explore-icon"
        onClick={() => navigate("/admin/save")}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.15, delay: 0.03 }}
      >
        {" "}
        <SaveIcon />
        <label>Savings</label>
      </motion.div>{" "}
      {/* <motion.div
        className="explore-icon"
        onClick={() => navigate("/admin/repayment")}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.1, delay: 0.04 }}
      >
        {" "}
        <RepaymentIcon />
        <label>Repayments</label>
      </motion.div> */}
      <motion.div
        className="explore-icon"
        onClick={() => navigate("/admin/docs")}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.15, delay: 0.05 }}
      >
        {" "}
        <StorageIcon />
        <label>Documents</label>
      </motion.div>{" "}
      <motion.div
        className="explore-icon"
        onClick={() => navigate("/admin/interestrecord")}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.15, delay: 0.06 }}
      >
        {" "}
        <InterestIcon />
        <label>Interest Record</label>
      </motion.div>{" "}
      <motion.div
        className="explore-icon"
        onClick={() => navigate("/admin/vouchers")}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.15, delay: 0.07 }}
      >
        {" "}
        <VouchersIcon />
        <label>Vouchers</label>
      </motion.div>{" "}
      <motion.div
        className="explore-icon  explore-icon-loans"
        onClick={() => navigate("/admin/applyloans")}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.15, delay: 0.08 }}
      >
        {" "}
        <ApplyloansIcon />
        <label>Apply Loans</label>
      </motion.div>{" "}
      <motion.div
        className="explore-icon  explore-icon-loans"
        onClick={() => navigate("/admin/chatroom")}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.15, delay: 0.08 }}
      >
        {" "}
        <ChatIcon />
        <label>Chat Room</label>
      </motion.div>{" "}
    </main>
  );
}

export default ExploreContainer;
